export const environment = {
  production: false,
  apiBaseUrl: 'https://swayprodevapi.appskeeper.in/api/v1',
  folderName: '',
  panelUrl: 'https://swayprodevapi.appskeeper.in', 
  API_KEY: '1234',
  PLATFORM: '3',
  FINGER_PRINT: 'Basic cGV0OnNvY2lhbA==',
  MAP_API_KEY: 'AIzaSyCQbgiBypxCkHFzd3BO-nn8nxo_ttDH0eo',
  tokenRememberKey: '',
  tokenKey: 'swaypromo_token',
 
};
 