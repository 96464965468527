import { importExpr } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { ToastService } from './component/toast-notification/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  notifyForm!: FormGroup;
  @ViewChild('email', { static: true }) email!: ElementRef;
  submitted = false;
  isSuccess:boolean=false;
  constructor(
    private _service:CommonService,
    private _toaster:ToastService
  ){
      
    this.createForm();
  }

  createForm(){
    this.notifyForm = new FormGroup({
      email:new FormControl('',[Validators.required, Validators.email])
    })
  }

  get f() { return this.notifyForm.controls }

  ngOnInit(): void {
  
   }

  notifyMe(f:any){
    
     this.submitted = true;
    if (!this.notifyForm.valid) {
      this.email.nativeElement.focus();
      return;
    }
    console.log("f",f.value);


    this._service.notifyApi(f.value).subscribe((res:any)=>{
        console.log("res");
    this._toaster.success("Sway with sarah will notify you by email")
    this.notifyForm.reset();
    this.notifyForm.markAsPristine();
    this.isSuccess=true;
    setTimeout(() => {
      this.isSuccess=false;
    }, 2000);
    this.submitted=false;
        
    },rej=>{
      console.log(rej)
    });

  }
 }
