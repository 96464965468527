import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _http:HttpClient
  ) { }

  
  notifyApi=(body:any)=>this._http.post(`${environment.apiBaseUrl}/promotional/notification-emails`,body)


  
  

}
