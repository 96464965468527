import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
 import { InjectionToken, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { tap } from 'rxjs/operators';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    let apiUrl;
    const isApiUrl = req.url.charAt(0) === '~';
    const authorization =`basic ${btoa('sway:sway@123')}`;
    let headers: any={}; 
    headers = {
      authorization: req.headers.get('Authorization') || authorization,
      api_key: environment.API_KEY,
      platform: environment.PLATFORM,
      timezone: `${-new Date().getTimezoneOffset()*60*1000}`,
      language:'en', 
     }
    return next.handle(req.clone({
      setHeaders: headers,
      url: isApiUrl ? `${environment.apiBaseUrl}${req.url.substr(1)}` : req.url // add base url
    })).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.status !== 201) {
           }
        }
        // return throwError(error);
      }, event => {
        if (event instanceof HttpErrorResponse) {

        }
      })
    );
  }
}
