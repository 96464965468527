<!-- <router-outlet></router-outlet> -->

<div class="wrapper">
    <div class="nav">
        <div class="brand">
            <img src="assets/images/logo.png" alt="logo">
        </div>
        <div class="right-nav">
            App coming soon
        </div>
    </div>
    <div class="inner-wrapper">
        <div class="main-head">
            Coming Soon
        </div>
        <div class="main-text">
        A health and fitness community platform for interactive workouts and more!
        </div>
        <div class="input-feild">
            <form  [formGroup]="notifyForm" (ngSubmit)="notifyMe(notifyForm)" class="input-feild">

            <input type="text" 
            class="input-cst" 
            placeholder="Please enter your email address"
            #email
            
            type="email" 
            maxlength="120"
            formControlName="email"
            required 
            > 
            <button class="notify" type="submit">Notify Me</button>
            <div class="error" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
               <span> Email is required</span>
            </div>
            <div class="error" *ngIf="(f.email.touched || submitted) && f.email.errors?.email">Email is invalid</div>
           
            <div class="white" *ngIf="isSuccess">
                Sway with sarah will notify you by email
            </div>
            
           </form>
         </div>
        <div class="hint-text">
           Keep me updated on the latest news!
        </div>
    </div>
</div>



